import { useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  return (
    <div>
      <div
        className={`md:py-[40px] sm:py-[60px] py-[40px] border-y-[1px] ${
          location?.pathname === "/products" || location?.pathname === "/our-service" ? "mt-0" : "md:mt-24 mt-12"
        }`}
      >
        <div className="mx-[4%] md:mx-[5%] xl:mx-[8%]">
          <h1 className="2xl:text-[50px] lg:text-[38px] text-[25px] text-[20px]  font-normal font-Suisse  lg:leading-[56px] sm:leading-[40px] leading-[30px] text-[#04128A] lg:text-left text-center">
            Your Needs Are Our Priority{" "}
            <span className=" text-[#777777]">- We're Here to Assist</span>
          </h1>
        </div>
      </div>
      <div className="mx-[3%] md:mx-[5%] xl:mx-[8%] flex md:justify-end justify-center md:py-[40px] py-[25px]">
        <div>
          <h6 className="font-Suisse  text-[14px] font-semibold text-[#0B0B0B]">
            Copyright ©2023. All rights reserved
          </h6>
        </div>
      </div>
    </div>
  );
};
export default Footer;
