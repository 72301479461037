import ReactPlayer from "react-player";
const Vision = () => {
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/anza-aboutpage.mp4";
  return (
    <div className="bg-[#081022]">
      <div className="mx-[4%] md:mx-[5%] xl:mx-[8%] lg:py-[110px] md:py-[80px] py-[70px] ">
        <h1 className="lg:text-[65px] md:text-[55px] text-[36px] leading-[74px] font-Suisse font-semibold text-[#4A81FF]  lg:mb-12 sm:mb-12 mb-6">
          Vision
        </h1>
        <div className="lg:w-[70%] w-full">
          <p className="md:text-[18px] text-[17px] leading-[27px] text-[#9FAAC1] font-normal font-Suisse ">
            "Our vision is to be a globally recognized and trusted leader in
            multi-sector trading, representing excellence, integrity, and
            sustainability. We aspire to continually expand our presence in
            diverse markets, leveraging our expertise and network to create
            opportunities and deliver value. We aim to be a catalyst for
            positive change, driving innovation and sustainable practices in
            each sector we operate in, and contributing to the betterment of
            communities and the world."
          </p>
        </div>
        <div className="flex justify-center lg:mt-20 mt-12">
          <div className="lg:w-[60%] w-full ">
            <ReactPlayer
              url={video}
              controls={false}
              loop
              playing
              playsinline={true}
              muted={true}
              className="video-container3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Vision;
