import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
const CarouselResponsive = () => {
  const responsive2 = {
    tablet: {
      breakpoint: { max: 1023, min: 767 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 766, min: 0 },
      items: 1,
    },
  };
  const data = [
    {
      id: 1,
      name: "Petroleum",
      subtitle:
        "Experienced oil and petroleum trading through transparent trade practices.",
      image: require("../../assets/img/petroleum.jpg"),
    },
    {
      id: 2,
      name: "Chemical industry",
      subtitle:
        "Specializing in the distribution of liquid and solid chemicals to industries.",
      image: require("../../assets/img/chemical.jpg"),
    },
    {
      id: 3,
      name: "Petrochemical",
      subtitle:
        "We help you gain the best overall insights on petrochemical production and cost analysis.",
      image: require("../../assets/img/petrochemical2.jpg"),
    },
    {
      id: 4,
      name: "Metals and Minerals Industry",
      subtitle:
        "Proficient in sourcing and delivering a wide spectrum of metals and minerals to various sectors.",
      image: require("../../assets/img/metal-mineral2.jpg"),
    },
    {
      id: 5,
      name: "Food Commodities",
      subtitle:
        "Expertise in the trade and distribution of food commodities, offering transparent sourcing and supply chain solutions.",
      image: require("../../assets/img/food-commodities2.jpg"),
    },
  ];
  return (
    <div>
      <Carousel
        responsive={responsive2}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1800}
        arrows={false}
        dotListClass="custom-dot-list-style"
        showDots={true}
        className="flex "
      >
        {data?.map((item, index) => (
          <div key={index} className="w-[100%]  ">
            <div className="bg-[#E9E9E9] md:p-[24px] p-5 sm:h-[350px] h-[370px]  flex flex-col justify-between md:mx-4 mx-0">
              <div>
                <h3 className="font-Suisse font-semibold text-[#04128A] lg:text-[31px] md:text-[29px] text-[28px] leading-[34px] mb-[14px]">
                  {item.name}
                </h3>
                <p className="text-[17px] font-semibold leading-[21px] font-Suisse text-[#000] mr-[3px]">
                  {item.subtitle}
                </p>
              </div>
              <div className="flex md:justify-end justify-center">
                <img
                  src={item.image}
                  alt=""
                  className="md:w-[200px] w-[100%] product-img"
                />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default CarouselResponsive;
