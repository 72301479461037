const Banner = () => {
  return (
    <div className="mx-[4%] md:mx-[5%] xl:mx-[8%]">
      <h1 className="xl:text-[72px] lg:text-[65px] sm:text-[50px] text-[40px] font-Suisse font-semibold text-[#04128A] sm:leading-[74px]  leading-[52px] text-left lg:mt-[80px] mt-[60px] lg:mb-[80px] mb-[50px] ">
        Anza's Legacy in the UAE
      </h1>
    </div>
  );
};
export default Banner;
