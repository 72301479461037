import { Route, Routes } from "react-router-dom";
// pages
import Homepage from "../container/homepage";
import About from "../container/about";
import OurService from "../container/our-service";
import Products from "../container/products";
import Contact from "../container/contact";
import NotFound from "../container/404page/404";
// layout for header and footer
import Layout from "../shared/index";

const AllPagesRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/our-service" element={<OurService />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Homepage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};
export default AllPagesRoutes;
