import { useNavigate, useLocation } from "react-router-dom";
import AnimateHeight from "react-animate-height";
import { useState } from "react";
import Logo2 from "../assets/img/WHITE_LOGO.png";
import Logo from "../assets/img/logo3.svg";

const Header = () => {
  const [height, setHeight] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={` w-full z-20 ${
        location?.pathname === "/" ? "absolute " : ""
      }`}
    >
      <div
        className={`mx-[4%] md:mx-[5%] xl:mx-[8%] flex justify-between ${
          location?.pathname === "/"
            ? "bg-[transparent] md:my-[45px] sm:my-[30px] my-[20px]"
            : "bg-[#fff] md:my-[45px] sm:my-[30px] my-[20px]"
        } `}
      >
        <div className="flex items-center">
          <div onClick={() => navigate("/")}>
            {location?.pathname === "/" ? (
              <img src={Logo2} alt="" className="h-[38px] cursor-pointer" />
            ) : (
              <img src={Logo} alt="" className="h-[38px] cursor-pointer" />
            )}
          </div>
          <div className="lg:block hidden ">
            <ul
              className={`flex items-center xl:pl-8 pl-4 ${
                location?.pathname === "/" ? "text-[#fff] " : "text-[#000]"
              }`}
            >
              <li
                className={`transition ease-in-out delay-150 duration-200 px-3 mx-1 py-[4px] text-[16px] font-medium font-Suisse hover:rounded-full rounded-full cursor-pointer border ${
                  location?.pathname === "/"
                    ? "bg-[#000] hover:bg-[#000] text-[#fff] border-transparent hover:bg-[#000] hover:text-[#fff]"
                    : "text-[#000] bg-[transparent] border-transparent hover:bg-[#000] hover:text-[#fff]"
                }`}
                onClick={() => navigate("/")}
              >
                Home
              </li>
              <li
                className={`transition ease-in-out delay-150 duration-200 px-3 mx-1 py-[4px] text-[16px] font-medium font-Suisse cursor-pointer  rounded-full hover:rounded-full border ${
                  location?.pathname === "/about"
                    ? "bg-[transparent] text-[#000] border-[1px] border-[#000] hover:bg-[#000] hover:text-[#fff]"
                    : " bg-[transparent] border-transparent hover:bg-[#000] hover:text-[#fff]"
                }`}
                onClick={() => navigate("/about")}
              >
                About
              </li>
              <li
                className={`transition ease-in-out delay-150 duration-200 px-3 mx-1 py-[4px] text-[16px]  font-medium font-Suisse cursor-pointer rounded-full hover:rounded-full border ${
                  location?.pathname === "/our-service"
                    ? "bg-[transparent] text-[#000] border-[1px] border-[#000] hover:bg-[#000] hover:text-[#fff]"
                    : " bg-[transparent] border-transparent hover:bg-[#000] hover:text-[#fff]"
                }`}
                onClick={() => navigate("/our-service")}
              >
                Our services
              </li>
              <li
                className={`transition ease-in-out delay-150 duration-200 px-3 mx-1 py-[4px] text-[16px]  font-medium font-Suisse cursor-pointer rounded-full hover:rounded-full border ${
                  location?.pathname === "/products"
                    ? "bg-[transparent] text-[#000] border-[1px] border-[#000] hover:bg-[#000] hover:text-[#fff]"
                    : " bg-[transparent] border-transparent hover:bg-[#000] hover:text-[#fff]"
                }`}
                onClick={() => navigate("/products")}
              >
                Products
              </li>
              <li
                className={`transition ease-in-out delay-150 duration-200 px-3 mx-1 py-[4px] text-[16px]  font-medium font-Suisse cursor-pointer rounded-full hover:rounded-full border ${
                  location?.pathname === "/contact"
                    ? "bg-[transparent] text-[#000] border-[1px] border-[#000]  hover:bg-[#000] hover:text-[#fff]"
                    : " bg-[transparent] border-transparent hover:bg-[#000] hover:text-[#fff]"
                }`}
                onClick={() => navigate("/contact")}
              >
                Contact
              </li>
            </ul>
          </div>
        </div>

        <div className="fixed right-[4%] md:right-[5%] xl:right-[8%] mt-[-8px] lg:block hidden z-10">
          <button
            className="py-[12px] transition ease-in-out delay-150 duration-200 xl:px-[20px] px-[12px] font-medium xl:text-[16px] lg:text-[15px] text-[14px] font-Suisse bg-[#04128A] hover:bg-[#F2BD00] text-white hover:text-[#000] rounded-full  "
            onClick={() => {
              navigate("/contact");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Schedule <br className="sm:hidden block" /> an appointment now
          </button>
        </div>
        <div className="block lg:hidden flex items-center">
          <div
            aria-expanded={height !== 0}
            aria-controls="panel"
            onClick={() => setHeight(height === 0 ? "auto" : 0)}
            className="cursor-pointer mb-1"
          >
            {location?.pathname === "/" ? (
              <img
                src={require("../assets/img/hamburger-white.png")}
                alt=""
                className="sm:h-[40px] h-[30px]"
              />
            ) : (
              <img
                src={require("../assets/img/hamburger.png")}
                alt=""
                className="sm:h-[40px] h-[30px]"
              />
            )}
          </div>
        </div>
      </div>
      <AnimateHeight
        id="panel"
        className="w-full left-0 absolute top-0 z-50"
        duration={500}
        height={height}
      >
        <div className="w-full flex flex-col justify-between h-[100vh] bg-[#04128A] p-6 ">
          <div className="flex justify-between ">
            <ul className="pt-10">
              <li
                className={`sm:text-[22px] text-[20px] py-2 sm:font-semibold font-medium  font-Suisse cursor-pointer ${
                  location?.pathname === "/" ? "text-[#F2BD00]" : "text-[#fff]"
                }`}
                onClick={() => {
                  navigate("/");
                  setHeight(0);
                }}
              >
                Home
              </li>
              <li
                className={`sm:text-[22px] text-[20px] py-2 sm:font-semibold font-medium  font-Suisse cursor-pointer ${
                  location?.pathname === "/about"
                    ? "text-[#F2BD00]"
                    : "text-[#fff]"
                }`}
                onClick={() => {
                  navigate("/about");
                  setHeight(0);
                }}
              >
                About
              </li>
              <li
                className={`sm:text-[22px] text-[20px] py-2 sm:font-semibold font-medium font-Suisse cursor-pointer ${
                  location?.pathname === "/our-service"
                    ? "text-[#F2BD00]"
                    : "text-[#fff]"
                }`}
                onClick={() => {
                  navigate("/our-service");
                  setHeight(0);
                }}
              >
                Our services
              </li>
              <li
                className={`sm:text-[22px] text-[20px] py-2 sm:font-semibold font-medium font-Suisse cursor-pointer ${
                  location?.pathname === "/products"
                    ? "text-[#F2BD00]"
                    : "text-[#fff]"
                }`}
                onClick={() => {
                  navigate("/products");
                  setHeight(0);
                }}
              >
                Products
              </li>
              <li
                className={`sm:text-[22px] text-[18px] py-2 sm:font-semibold font-medium font-Suisse cursor-pointer ${
                  location?.pathname === "/contact"
                    ? "text-[#F2BD00]"
                    : "text-[#fff]"
                }`}
                onClick={() => {
                  navigate("/contact");
                  setHeight(0);
                }}
              >
                Contact
              </li>
            </ul>
            <div
              className="text-[18px] font-semibold font-normal font-Suisse cursor-pointer"
              onClick={() => setHeight(0)}
            >
              <img
                src={require("../assets/img/Union.png")}
                alt=""
                className="h-[20px]"
              />
            </div>
          </div>
          <div>
            <div
              className="w-full border flex justify-center rounded-full py-3 bg-[#04128A] hover:bg-[#F2BD00] hover:text-[#000] text-[#fff] text-[16px] font-medium  font-Suisse cursor-pointer"
              onClick={() => {
                navigate("/contact");
                setHeight(0);
              }}
            >
              <button>Schedule an appointment now</button>
            </div>
          </div>
        </div>
      </AnimateHeight>
    </div>
  );
};
export default Header;
