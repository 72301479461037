import React from "react";
const LocationSection = () => {
  return (
    <div className="w-full ">
      <iframe
        title="anza"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.3994772367255!2d55.297269510530214!3d25.25714387757934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43c121942ab7%3A0x6a59a388abee9931!2sAnza%20GT!5e0!3m2!1sen!2sae!4v1700375558319!5m2!1sen!2sae"
        className="w-full h-[300px] lg:h-[200px]"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default LocationSection;
