const OilGas = () => {
  return (
    <div className="border-t-[1px] border-[#DDDDDD] bg-[#F5F5F5] ">
      <div className="mx-[4%] md:mx-[5%] xl:mx-[8%] lg:pt-24 lg:pb-20 py-12 flex lg:flex-row flex-col justify-between ">
        <div className="flex">
          {/*  Oil and Gas */}
          <div>
            <h4 className="md:text-[20px] text-[16px] text-[#000] font-medium px-4 font-Suisse py-2 bg-[#F2BD00] rounded-full">
            Oil and Gas
            </h4>
          </div>
        </div>
        <div className="xl:w-[65%] lg:w-[60%] w-full lg:mt-0 md:mt-10 mt-8">
          <div className="flex md:flex-row flex-col justify-between">
            <div className="md:w-[47%] w-full ">
              <div className="border-b-[1px] border-[#DDDDDD]">
                <h3 className="xl:text-[35px] lg:text-[30px] md:text-[27px] text-[25px] font-Suisse font-normal text-[#000] md:mb-3 mb-2">
                Base Oil
                </h3>
              </div>
              <div className="border-b-[1px] border-[#DDDDDD]">
                <h3 className="xl:text-[35px] lg:text-[30px] md:text-[27px] text-[25px]  font-Suisse font-normal text-[#000] md:my-3 my-2">
                Crude
                </h3>
              </div>
              <div className="md:border-none border-b-[1px] border-[#DDDDDD]">
                <h3 className="xl:text-[35px] lg:text-[30px] md:text-[27px] text-[25px] font-Suisse font-normal text-[#000] md:my-3 my-2">
                Naphtha
                </h3>
              </div>
            </div>
            <div className="md:w-[47%] w-full">
              <div className="border-b-[1px] border-[#DDDDDD]">
                <h3 className="xl:text-[35px] lg:text-[30px] md:text-[27px] text-[25px]  font-Suisse font-normal text-[#000] md:mb-3 md:mt-0 my-2">
                Fuel Oil
                </h3>
              </div>
              <div className="border-b-[1px] border-[#DDDDDD]">
                <h3 className="xl:text-[35px] lg:text-[30px] md:text-[27px] text-[25px]  font-Suisse font-normal text-[#000] md:my-3 my-2">
                Diesel
                </h3>
              </div>
              <div>
                <h3 className="xl:text-[35px] lg:text-[30px] md:text-[27px] text-[25px]  font-Suisse font-normal text-[#000] md:my-3 my-2">
                LPG
                </h3>
              </div>
            </div>
          </div>
          <div className="md:mt-8 mt-7">
            <img src={require("../../assets/img/product2.png")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OilGas;
