const Service = () => {
  const services = [
    {
      id: 1,
      title: "Petroleum",
      description:
        "Experienced oil and petroleum trading through transparent trade practices.",
      img: require("../../assets/img/petrol-img.png"),
    },
    {
      id: 2,
      title: "Chemical Industry",
      description:
        "Specializing in the distribution of liquid and solid chemicals to industries.",
      img: require("../../assets/img/chemical1.png"),
    },
    {
      id: 3,
      title: "Petrochemical",
      description:
        "We help you gain the best overall insights on petrochemical production and cost analysis.",
      img: require("../../assets/img/Petrochemical-os.jpg"),
    },
    {
      id: 4,
      title: "Metals and Minerals Industry",
      description:
        "Proficient in sourcing and delivering a wide spectrum of metals and minerals to various sectors.",
      img: require("../../assets/img/metalmineral-os.jpg"),
    },
    {
      id: 5,
      title: "Food Commodities",
      description:
        "Expertise in the trade and distribution of food commodities, offering transparent sourcing and supply chain solutions.",
      img: require("../../assets/img/foodcommodities-os.jpg"),
    },
  ];
  return (
    <div className="lg:pt-24 lg:pb-32 md:pt-16 pt-10 pb-[1px] bg-[#0C0E26]">
      <div className="mx-[4%] md:mx-[5%] xl:mx-[8%]">
        {/* our Services into add map loop */}
        {services.map((item) =>
          item?.id % 2 !== 0 ? (
            <div className="flex w-full lg:flex-row flex-col-reverse lg:mb-0 md:mb-16 mb-10">
              <div className="lg:w-[50%] w-full bg-[#151948] flex justify-center items-center ">
                <div className="text-center xl:mx-24 lg:mx-20 lg:my-0 md:m-20 mx-10 my-20">
                  <h2 className="xl:text-[35px] md:text-[30px] text-[25px] font-medium text-[#fff] font-Suisse ">
                    {item?.title}
                  </h2>
                  <div className="h-[4px] w-full bg-[#17284F] xl:my-8 my-7"></div>
                  <p className="xl:text-[25px] md:text-[20px] text-[18px] text-[#fff] font-normal opacity-[0.5]">
                    {item?.description}
                  </p>
                </div>
              </div>
              <div className="lg:w-[50%] w-full">
                <img src={item?.img} alt="" />
              </div>
            </div>
          ) : (
            <div className="flex lg:flex-row flex-col w-full lg:mb-0 mb-12">
              <div className="lg:w-[50%] w-full">
                <img src={item?.img} alt="" />
              </div>
              <div className="lg:w-[50%] w-full bg-[#151948] flex justify-center items-center">
                <div className="text-center xl:mx-24 lg:mx-20 lg:my-0 md:m-20 mx-10 my-20">
                  <h2 className="xl:text-[35px] md:text-[30px] text-[25px] font-medium text-[#fff] font-Suisse ">
                    {item?.title}
                  </h2>
                  <div className="h-[4px] w-full bg-[#17284F] xl:my-8 md:my-7 my-6"></div>
                  <p className="xl:text-[25px] md:text-[20px] text-[18px] text-[#fff] font-normal opacity-[0.5] ">
                    {item?.description}
                  </p>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default Service;
