const Banner = () => {
  return (
    <div className="mx-[4%] md:mx-[5%] xl:mx-[8%] lg:mt-[110px] mt-[80px] lg:mb-[80px] mb-[50px]">
      <h1 className="xl:text-[72px] lg:text-[65px] sm:text-[50px] text-[42px] font-Suisse font-semibold text-[#04128A] lg:leading-[84px] sm:leading-[70px] leading-[50px] text-left">
        Sourcing <span className="text-[#F2BD00]">Quality</span> Globally,{" "}
        <br />
        Delivering Excellence Every Trade
      </h1>
      <div className="flex justify-end">
        <div className="lg:w-[70%] w-full ">
          <p className="lg:pt-28 pt-8 font-Suisse lg:text-[25px] md:text-[20px] text-[18px] font-medium md:leading-[35px] leading-[28px] text-[#666666] ">
            Our team comprises of highly skilled and experienced professionals
            with decades of expertise in the industrial materials and
            commodities sector. This collective knowledge positions us as a top
            contender in general trading. At Anza Group, our commitment to
            quality products and exceptional customer care is at the core of our
            corporate mission.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Banner;
