const Section = () => {
  return (
    <div className=" border-y-[1px] ">
      <div>
        <div className=" lg:pb-[110px] md:pb-[80px] pb-[60px]">
          <div className="w-full">
            <div className="w-full ">
              <img src={require("../../assets/img/Rectangle-48.png")} alt="" />
            </div>
          </div>
          <div className="w-full lg:w-[90%] mt-16 px-[4%] lg:mx-[5%] xl:mx-[8%]">
            <h3 className="w-full lg:text-[35px] md:text-[33px] text-[25px] font-medium font-Suisse  lg:leading-[42px] leading-[40px] text-[#777777]  lg:mb-16 mb-10">
              Having been a{" "}
              <span className="text-[#F2BD00]">stalwart presence </span> in the
              GCC for five decades, Anza Group has not only carved a niche for
              itself but has also significantly contributed to the dynamic
              business landscape of the UAE. Our enduring tenure in the region
              has fostered deep-rooted relationships, local expertise, and an
              intimate understanding of the region's market dynamics.
            </h3>
          </div>
          <div className="flex justify-end px-[4%] lg:mx-[5%] xl:mx-[8%]">
            <div className=" mt-5 lg:w-[65%] w-full">
              <p className="lg:mb-8 mb-6 md:text-[18px] text-[17px]  font-normal text-[#666666] font-Suisse leading-[27px] ">
                This prolonged presence has enabled us to seamlessly adapt to
                the region's evolving economic and regulatory landscape,
                positioning us as a trusted and influential entity in the local
                and global markets.
              </p>
              <p className="md:text-[18px] text-[17px] font-normal text-[#666666] font-Suisse leading-[27px] r">
                {" "}
                Our dedication to excellence permeates every facet of our
                operation, serving as the driving force behind our success in
                each sector. As an integral part of the esteemed ANZA Capital
                Investment Group under the patronage of His Highness Sheikh
                Mohammad Bin Sultan Bin Hamdan Al Nahyan, we not only ensure
                financial stability but also leverage strategic support, further
                solidifying our prominent position. This enduring commitment to
                continuous growth and innovation shapes the future of these
                diverse business sectors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section;
