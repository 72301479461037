const Banner = () => {
  return (
    <div className="mx-[4%] md:mx-[5%] xl:mx-[8%]">
      <h1 className="xl:text-[72px] lg:text-[65px] sm:text-[50px] text-[42px] font-Suisse font-semibold text-[#04128A] sm:leading-[74px]  leading-[50px] text-left lg:mt-[110px] mt-[80px] lg:mb-[80px] mb-[50px] ">
        Appointment Booking
      </h1>
    </div>
  );
};
export default Banner;
