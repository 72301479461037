import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="mx-[4%] md:mx-[5%] xl:mx-[8%] pt-[8rem] pb-[6rem]">
      {/* 404 page Image */}
      <div className="flex justify-center w-full ">
        <img src={require("../../assets/img/notfound.png")} alt="404" />
      </div>
      <div className="md:pt-16 pt-12">
        {/* Head of the page content */}
        <h2 className="md:text-[23px] text-[18px] font-medium text-center font-Suisse text-[#000]">
          THE PAGE YOU WERE LOOKING FOR DOESN' T EXIST
        </h2>
        {/* Return HomePage button */}
        <div className="flex justify-center md:mt-8 mt-6">
          <button
            className="py-[12px] transition ease-in-out delay-150 duration-200 xl:px-[20px] px-[16px] font-medium sm:text-[16px] text-[15px] font-Suisse bg-[#04128A] hover:bg-[#F2BD00] text-white hover:text-[#000] rounded-full  "
            onClick={() => {
              navigate("/");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Return Home Page
          </button>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
