import { useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Image from "../../assets/img/homeBannerPlaceholder.png";
const Banner = () => {
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/anza-homepage.mp4";
  return (
    <>
      <div className="relative">
        <div>
          {loader && <img src={Image} alt="" />}
          <ReactPlayer
            url={video}
            controls={false}
            loop
            playing
            playsinline={true}
            muted={true}
            onReady={() => setLoader(false)}
            onBufferEnd={() => setLoader(false)}
            className="video-container"
          />
        </div>
        <div className="absolute bottom-0 mx-[4%] md:mx-[5%] xl:mx-[8%] 2xl:mb-32 xl:mb-24 lg:mb-16 md:mb-10 mb-6  ">
          <h1 className="xl:text-[72px] lg:text-[60px] md:text-[52px] sm:text-[50px] text-[42px]  font-semibold font-Suisse text-[#fff]  xl:leading-[74px] lg:leading-[62px] md:leading-[58px] leading-[50px] tracking-[1px]">
            Fueling the Future, <br />
            Strengthening Economies.
          </h1>
          <div
            className="md:p-4 md:mt-4 mt-3 p-3 bg-[#04128A] hover:bg-[#F2BD00] hover:text-[#000] text-[#fff] text-[16px] font-medium  font-Suisse cursor-pointer rounded-full mt-2 w-fit lg:hidden block"
            onClick={() => {
              navigate("/contact");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <button>Schedule an appointment now</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
