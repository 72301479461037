import Banner from "../../component/homepage/banner";
import FiveDecade from "../../component/homepage/five-decade";
import OurIndustries from "../../component/homepage/our-industries";
import OurExpertise from "../../component/homepage/our-expertise";
// import CompanyName from "../../component/homepage/company-name";
const Homepage = () => {
  return (
    <>
      <Banner />
      <FiveDecade />
      <OurIndustries />
      <OurExpertise />
      {/* <CompanyName /> */}
    </>
  );
};
export default Homepage;
