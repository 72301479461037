import Banner from "../../component/our-service/banner";
import Service from "../../component/our-service/services";
const OurService = () => {
  return (
    <div>
      <Banner />
      <Service />
    </div>
  );
};
export default OurService;
