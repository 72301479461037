import ReactPlayer from "react-player";
const FiveDecade = () => {
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/Anza_Dubaiview.mp4";
  return (
    <div className="mx-[4%] md:mx-[5%] xl:mx-[8%]  xl:my-[115px] sm:my-[85px] my-[45px] flex lg:flex-row flex-col justify-between">
      <div className="lg:mb-0 mt-[14px] mb-[20px] lg:block hidden">
        <img
          src={require("../../assets/img/right-arrow.png")}
          alt=""
          className="w-[48px]"
        />
      </div>
      <div className="lg:w-[65%] w-full ">
        <h1 className="md:text-[39px] text-[30px] font-normal font-Suisse text-[#858585] md:leading-[48px] leading-[40px]">
          With a <span className="text-[#04128A]">five-decade legacy</span> in
          the GCC, Anza Group, headquartered in Dubai, stands as a prominent
          corporate entity in diverse industries.
        </h1>
        <div className="sm:my-[60px] my-[40px] ">
          <ReactPlayer
            url={video}
            controls={false}
            loop
            playing
            playsinline={true}
            muted={true}
            className="video-container2"
          />
        </div>
        <p className="md:text-[18px] text-[18px] font-normal leading-[27px] font-Suisse text-[#666666]">
          Our core expertise lies in the trading of petroleum products, active
          engagement in the oil and gas sector, and the distribution of
          petrochemicals and commodities across the globe. This
          commitment is underpinned by our affiliation with the prestigious ANZA
          Investment Group, reinforcing our position as a prominent presence in
          the industry.
        </p>
      </div>
    </div>
  );
};
export default FiveDecade;
