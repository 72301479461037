import { useNavigate } from "react-router-dom";
import { isSafari } from "react-device-detect";

const OurExpertise = () => {
  /* Safari Browser add if else condition */
  const ImageClassHandler = () => {
    if (isSafari) {
      return "lg:h-auto 2xl:h-[-webkit-fill-available] lg:w-full";
    } else {
      return "lg:h-full";
    }
  };
  // navigate
  const navigate = useNavigate();
  return (
    <div className="bg-[#0B152C] ">
      <div className=" mx-[4%] md:mx-[5%] xl:mx-[8%] py-[100px] flex lg:flex-row flex-col border-b-4 border-[#17284F]">
        <div className="lg:w-[55%] w-full ">
          <h3 className="text-[#4A81FF] font-semibold md:text-[39px] sm:text-[36px] text-[28px] leading-[48px] font-Suisse mb-[33px] lg:text-left text-center">
            A Culture of Excellence
          </h3>
          <h6 className="md:text-[25px] text-[20px] font-Suisse leading-[31px] font-medium text-[#fff] lg:text-left text-center">
            With 50 years of experience, we bring deep{" "}
            <br className="md:block hidden" /> industry knowledge.
          </h6>
        </div>
        <div className="lg:mt-0 pt-[40px] flex lg:justify-start justify-center ">
          <img
            src={require("../../assets/img/fifty.png")}
            alt=""
            className="lg:h-[260px] md:h-[200px] h-[160px]"
          />
        </div>
      </div>
      <div className="mx-[4%] md:mx-[5%] xl:mx-[8%] md:pt-[100px] lg:pb-[100px] pt-[70px] ">
        <h1 className="text-[#fff] font-Suisse lg:text-[50px] text-[36px] font-semibold leading-[64px] ">
          Our Expertise
        </h1>
      </div>
      <div className="flex lg:flex-row flex-col lg:pt-0 pt-[35px]">
        <div className="lg:w-[50%] md:mx-0 mx-[4%] overflow-hidden ">
          <div className="h-full">
            <img
              src={require("../../assets/img/market.jpg")}
              alt=""
              className={`image-zoom transition ease-in-out delay-150 duration-200 ${ImageClassHandler()}`}
            />
          </div>
        </div>
        <div className="2xl:px-[140px] lg:px-[70px] px-[40px] xl:py-[90px] lg:py-[50px] py-[40px] flex flex-col justify-between lg:w-[50%] w-full bg-[#081022]">
          <h3 className="md:text-[35px] text-[25px] font-medium md:leading-[45px] leading-[31px] lg:mb-0 md:mb-[25px] mb-[18px] font-Suisse text-[#fff] ">
            Market Pioneers
          </h3>
          <div>
            <p className="md:text-[18px] text-[16px] font-medium leading-[24px] text-[#fff] md:mb-[40px] mb-[28px] opacity-[0.6] w-full lg:w-[90%]">
              We take pride in being true pioneers in the dynamic world of
              trading. Our journey has been marked by a relentless commitment to
              excellence, innovation, and a steadfast dedication to pushing the
              boundaries of what's possible in the industry.
              <br />
              <br />
              <b>Unwavering Commitment to Trading Excellence:</b>
              <br />
              With a rich history of navigating market trends and evolving trade
              landscapes, we have consistently demonstrated an unwavering
              commitment to trading excellence.
            </p>
            <div className="flex ">
              <button
                onClick={() => navigate("/contact")}
                className="px-[23.9px] pt-[14.34px] pb-[12.34px] md:text-[16px] text-[14px] bg-[#fff] font-Suisse text-[#081022] rounded-full font-medium transition ease-in-out delay-150 duration-200 hover:text-[#000] hover:bg-[#F2BD00]"
              >
                Leave Message
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col-reverse lg:pt-0 sm:pt-[35px] pt-[25px]">
        <div className="2xl:px-[140px] lg:px-[70px] px-[40px] xl:py-[90px] lg:py-[50px] py-[40px]  flex flex-col justify-between lg:w-[50%] w-full bg-[#081022]">
          <h3 className="md:text-[35px] text-[25px] font-medium md:leading-[45px] leading-[31px] lg:mb-0 md:mb-[25px] mb-[18px] font-Suisse text-[#fff] ">
            Comprehensive Market Analysis
          </h3>
          <div>
            <p className="md:text-[18px] text-[16px] font-medium leading-[24px] text-[#fff] md:mb-[40px] mb-[28px] opacity-[0.6] w-full lg:w-[90%]">
              Our commitment to excellence extends to our unparalleled expertise
              in comprehensive market analysis. With a foundation built on years
              of invaluable experience, we take pride in offering a strategic
              advantage to all stakeholders through meticulous examination of
              market dynamics.
              <br />
              <br />
              <b>In-Depth Understanding:</b>
              <br />
              Our comprehensive approach ensures a nuanced understanding of
              market forces, enabling us to provide informed recommendations
              tailored to your specific needs.
            </p>
            <div className="flex">
              <button
                onClick={() => navigate("/contact")}
                className="px-[23.9px] pt-[14.34px] pb-[12.34px] md:text-[16px] text-[14px] bg-[#fff] font-Suisse text-[#081022] rounded-full font-medium transition ease-in-out delay-150 duration-200 hover:text-[#000] hover:bg-[#F2BD00]"
              >
                Leave Message
              </button>
            </div>
          </div>
        </div>
        <div className="lg:w-[50%] md:mx-0 mx-[4%] overflow-hidden">
          <div className="h-full">
            <img
              src={require("../../assets/img/solution.jpg")}
              alt=""
              className={`image-zoom transition ease-in-out delay-150 duration-200 ${ImageClassHandler()}`}
            />
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col lg:pt-0 sm:pt-[35px] pt-[25px]">
        <div className="lg:w-[50%] md:mx-0 mx-[4%] overflow-hidden">
          <div className="h-full">
            <img
              src={require("../../assets/img/focus.jpg")}
              alt=""
              className={`image-zoom transition ease-in-out delay-150 duration-200 ${ImageClassHandler()}`}
            />
          </div>
        </div>
        <div className="2xl:px-[140px] lg:px-[70px] px-[40px] 2xl:py-[90px] lg:py-[50px] py-[40px]  flex flex-col justify-between lg:w-[50%] w-full bg-[#081022]">
          <h3 className="md:text-[35px] text-[25px] font-medium leading-[45px] leading-[31px] font-Suisse lg:mb-0 md:mb-[25px] mb-[18px] text-[#fff]">
            Holistic Solutions
          </h3>
          <div>
            <p className="md:text-[18px] text-[16px] font-medium leading-[24px] text-[#fff] mb-[40px] opacity-[0.6] w-full lg:w-[90%]">
              Our commitment to excellence goes beyond mere transactions – we
              provide holistic solutions that cater to the diverse needs of our
              clients
              <br />
              <br />
              <b>Diversified Portfolio:</b>
              <br />
              Explore a world of possibilities with our diverse range of
              services. From petroleum trading to strategic sector engagement,
              our portfolio is designed to address the multifaceted demands of
              the modern business landscape. We pride ourselves on being your
              one-stop solution provider.
            </p>
            <div className="flex lg:pb-0 sm:pb-[35px] pb-[25px]">
              <button
                onClick={() => navigate("/contact")}
                className="px-[23.9px] pt-[14.34px] pb-[12.34px] md:text-[16px] font-medium text-[14px] bg-[#fff] font-Suisse text-[#081022] rounded-full transition ease-in-out delay-150 duration-200 hover:text-[#000] hover:bg-[#F2BD00]"
              >
                Leave Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurExpertise;
