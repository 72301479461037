import { useState } from "react";
import LocationSection from "./Location";
const ContactForm = () => {
  const [userDetail, setUserDetail] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const onChangeHandler = (key, value) => {
    let duplicate = { ...userDetail };
    duplicate[key] = value;
    setUserDetail(duplicate);
  };

  console.log("gggg", userDetail);

  const submitHandler = (e) => {
    e.preventDefault();
    window.location.href = `mailto:sales@anzagt.com?subject=${userDetail?.subject}&body=${userDetail?.message}`;
  };
  return (
    <div className="border-t-[1px] ">
      <div className="mx-[4%] md:mx-[5%] xl:mx-[8%] flex flex-col lg:flex-row justify-between pt-[80px] pb-[20px]">
        <div className="w-full lg:w-[58%]">
          <form onSubmit={(e) => submitHandler(e)}>
            <div className="mb-6">
              <input
                type="text"
                placeholder="Name"
                className="p-5 w-full bg-[#E9E9E9] text-[#000] text-[16px] font-Suisse font-medium placeholder-[#000] border-none outline-none "
                required
                onChange={(e) => {
                  onChangeHandler("name", e.target.value);
                }}
              />
            </div>
            <div className="mb-6">
              <input
                type="email"
                placeholder="Email"
                className="p-5 w-full bg-[#E9E9E9] text-[#000] text-[16px] font-Suisse font-medium placeholder-[#000] border-none outline-none"
                required
                onChange={(e) => {
                  onChangeHandler("email", e.target.value);
                }}
              />
            </div>
            <div className="mb-6">
              <input
                type="text"
                placeholder="Subject"
                className="p-5 w-full bg-[#E9E9E9] text-[#000] text-[16px] font-Suisse font-medium placeholder-[#000] border-none outline-none"
                required
                onChange={(e) => {
                  onChangeHandler("subject", e.target.value);
                }}
              />
            </div>
            <div className="mb-6">
              <textarea
                type="text"
                cols={5}
                rows={14}
                placeholder="Message"
                className="p-5 w-full bg-[#E9E9E9] text-[#000] text-[16px] font-Suisse font-medium placeholder-[#000] border-none outline-none"
                required
                onChange={(e) => {
                  onChangeHandler("message", e.target.value);
                }}
              />
            </div>
            <div className="lg:block hidden">
              <div className="mt-12 flex justify-center lg:justify-start">
                <button
                  type="submit"
                  className="pt-[18px] pb-[16px] transition ease-in-out delay-150 duration-200 px-[28px] font-medium sm:text-[18px] text-[14px] font-Suisse bg-[#04128A] hover:bg-[#F2BD00] text-[#fff] hover:text-[#000] rounded-full border-none outline-none"
                >
                  Send Message
                </button>
              </div>
            </div>
            <div className="lg:hidden block">
              <div className="sm:mt-8 mt-6 py-5 flex justify-center cursor-pointer transition ease-in-out delay-150 duration-200  font-medium sm:text-[18px] text-[16px] font-Suisse bg-[#04128A] hover:bg-[#F2BD00] text-[#fff] hover:text-[#000] rounded-full border-none outline-none">
                <button type="submit" >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="w-full lg:w-[35%] pt-16 lg:pt-0">
          <LocationSection />
          <div className="my-10">
            <h6 className="text-[21px] font-normal leading-[28px] font-Suisse text-[#000]">
              Corporate Headquarters: <br />
              <span className="font-semibold">
                EMAAR Square, Building #1, 5th Floor, Office #503, Dubai, UAE{" "}
              </span>
            </h6>
          </div>
          <div>
            <h6 className="text-[15px] font-medium leading-[20px] font-Suisse ">
              Phone:{" "}
            </h6>
            <h4 className="text-[15px] font-semibold leading-[20px] font-Suisse ">
              Main Office: +971 54 701 6344
            </h4>
          </div>
          <div className="mt-5">
            <h6 className="text-[15px] font-medium leading-[20px] font-Suisse ">
              Email:{" "}
            </h6>
            <h4 className="text-[15px] font-semibold leading-[20px] font-Suisse ">
              General Inquiries:{" "}
              <span>
                <a href="mailto:sales@anzagt.com">sales@anzagt.com</a>
              </span>
            </h4>
          </div>
          <div className="mt-5">
            <h6 className="text-[15px] font-medium leading-[20px] font-Suisse ">
              Working Hours:
            </h6>
            <h4 className="text-[15px] font-semibold leading-[20px] font-Suisse ">
              Monday to Friday: 8:00 AM - 5:00 PM (GST)
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
