import Banner from "../../component/products/banner";
import Petrochemical from "../../component/products/petrochemicals-polymers";
import OilGas from "../../component/products/oil-gas";
import MineralsMetals from "../../component/products/minerals-metals";
import AgroCommodities from "../../component/products/agro-commodities";
const Products = () => {
  return (
    <>
      <Banner />
      <Petrochemical />
      <OilGas />
      <MineralsMetals />
      <AgroCommodities />
    </>
  );
};
export default Products;
