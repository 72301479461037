import React from "react";
import Carousel from "react-multi-carousel";
import CarouselResponsive from "./carousel-respon";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 2399 },
    items: 5,
  },
  desktop2: {
    breakpoint: { max: 2399, min: 1899 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1899, min: 1399 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 1399, min: 1024 },
    items: 2,
  },
};
const data = [
  {
    id: 1,
    name: "Petroleum",
    subtitle:
      "Experienced oil and petroleum trading through transparent trade practices.",
    image: require("../../assets/img/petroleum.jpg"),
  },
  {
    id: 2,
    name: "Chemical industry",
    subtitle:
      "Specializing in the distribution of liquid and solid chemicals to industries.",
    image: require("../../assets/img/chemical.jpg"),
  },
  {
    id: 3,
    name: "Petrochemical",
    subtitle:
      "We help you gain the best overall insights on petrochemical production and cost analysis.",
    image: require("../../assets/img/petrochemical2.jpg"),
  },
  {
    id: 4,
    name: "Metals and Minerals Industry",
    subtitle:
      "Proficient in sourcing and delivering a wide spectrum of metals and minerals to various sectors.",
    image: require("../../assets/img/metal-mineral2.jpg"),
  },
  {
    id: 5,
    name: "Food Commodities",
    subtitle:
      "Expertise in the trade and distribution of food commodities, offering transparent sourcing and supply chain solutions.",
    image: require("../../assets/img/food-commodities2.jpg"),
  },
];

class WithScrollbar extends React.Component {
  state = { additionalTransfrom: 0 };
  render() {
    const CustomSlider = ({ carouselState }) => {
      let value = 0;
      let carouselItemWidth = 0;
      if (this.Carousel) {
        carouselItemWidth = this.Carousel.state.itemWidth;
        const maxTranslateX = Math.round(
          carouselItemWidth *
            (this.Carousel.state.totalItems -
              this.Carousel.state.slidesToShow) +
            150
        );
        value = maxTranslateX / 100;
      }
      const { transform } = carouselState;
      return (
        <div className="custom-slider1  ">
          <div className="mr-[3%] md:mr-[5%] xl:mr-[8%]">
            <input
              type="range"
              value={Math.round(Math.abs(transform) / value)}
              defaultValue={0}
              max={
                (carouselItemWidth *
                  (carouselState.totalItems - carouselState.slidesToShow) +
                  (this.state.additionalTransfrom === 150 ? 0 : 150)) /
                value
              }
              onChange={(e) => {
                if (this.Carousel.isAnimationAllowed) {
                  this.Carousel.isAnimationAllowed = false;
                }
                const nextTransform = e.target.value * value;
                const nextSlide = Math.round(nextTransform / carouselItemWidth);
                if (
                  e.target.value === 0 &&
                  this.state.additionalTransfrom === 150
                ) {
                  this.Carousel.isAnimationAllowed = true;
                  this.setState({ additionalTransfrom: 0 });
                }
                this.Carousel.setState({
                  transform: -nextTransform,
                  currentSlide: nextSlide,
                });
              }}
              className="custom-slider__input1 "
            />
          </div>
        </div>
      );
    };
    return (
      <div className=" xl:ml-[8%] lg:ml-[8%] xl:mr-0 lg:mr-0  mx-[4%] lg:my-[115px] sm:my-[75px] mt-[50px] mb-[40px]">
        <h1 className="font-Suisse lg:text-[50px] text-[36px] font-semibold text-[#0B0B0B] leading-[64px] md:mb-12 mb-8">
          Our industries
        </h1>
        <div className="lg:block hidden">
          <Carousel
            ssr={false}
            ref={(el) => (this.Carousel = el)}
            partialVisbile={false}
            autoPlay
            loop
            infinite={true}
            autoPlaySpeed={2000}
            customButtonGroup={<CustomSlider />}
            itemClass="slider-image-item1"
            responsive={responsive}
            containerClass="carousel-container-with-scrollbar1"
            additionalTransfrom={-this.state.additionalTransfrom}
            beforeChange={(nextSlide) => {
              if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
                this.setState({ additionalTransfrom: 150 });
              }
              if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
                this.setState({ additionalTransfrom: 0 });
              }
            }}
          >
            {data?.map((item, index) => (
              <div
                className="bg-[#E9E9E9] md:p-8 p-6 lg:w-[450px] md:w-[400px] sm:-[350px] w-[300px] sm:h-[350px] h-[300px] flex flex-col justify-between lg:mr-[10px]  mr-0 mt-[33px]  "
                key={index}
              >
                <div>
                  <h3 className="font-Suisse font-semibold text-[#04128A] lg:text-[31px] md:text-[26px] leading-[34px] text-[20px] mb-[14px]">
                    {item.name}
                  </h3>
                  <p className="lg:text-[17px] md:text-[15px] text-[13px] font-semibold leading-[21px] 2xl:leading-[25px] font-Suisse text-[#000000] mr-[3px]">
                    {item.subtitle}
                  </p>
                </div>
                <div className="flex justify-end">
                  <img
                    src={item.image}
                    alt=""
                    className=" md:h-[120px] h-[120px]"
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <div className="lg:hidden block overflow-hidden  ">
          <CarouselResponsive />
        </div>
      </div>
    );
  }
}
export default WithScrollbar;
